<template>
  <div class="payment-block">
    <div class="p-4"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['common_NPSchedule', 'common_kiev', 'common_lviv', 'payments_payByCard', 'common_kievSchedule', 'common_lvivSchedule', 'common_lvivSchedule1', 'common_lvivSchedule2'])"></span>
      <div class="">
        <div class="payment-block__name">
          {{$t('common_NPSchedule.localization_value.value')}}
        </div>
<!--        <div class="payment-block__description">-->
<!--          {{$t('payments_balanceUp.localization_value.value')}}-->
<!--        </div>-->
      </div>
      <div class="">

        <div class="payment-block__radio mb-2">
          <div class="payment-block__radio-i">

            <RadioDefault
                :name="'np-lviv'"
                :label="$t('common_lviv.localization_value.value')"
                :value="city === 'lviv'"
                @change.native="city = 'lviv'"
            />
          </div>
          <div class="payment-block__radio-i">

            <RadioDefault
                :name="'np-kiev'"
                :label="$t('common_kiev.localization_value.value')"
                :value="city === 'kiev'"
                @change.native="city = 'kiev'"
            />
          </div>
        </div>



        <template v-if="city === 'kiev'">
          <div class="payment-block__currently mt-3">
            <div v-html="$t('common_kievSchedule.localization_value.value')"></div>

<!--            <div class="name-city">Київ</div>-->
<!--            <div class="d-flex">-->
<!--              <div class="day">Пн-Пт:</div>-->
<!--              <div class="time">-->
<!--                <p>9:00</p>-->
<!--                <p>12:00</p>-->
<!--                <p>15:10</p>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </template>

        <template v-if="city === 'lviv'">
          <div class="payment-block__currently mt-3">

            <div v-html="$t('common_lvivSchedule.localization_value.value')"></div>
            <div v-html="$t('common_lvivSchedule1.localization_value.value')"></div>
            <div v-html="$t('common_lvivSchedule2.localization_value.value')"></div>

<!--            <div class="name-city">Львів</div>-->
<!--            <div class="d-flex">-->
<!--              <div class="day">Пн:</div>-->
<!--              <div class="time"><span style="color:#000;">15:00 - 15:30</span></div>-->
<!--            </div>-->
<!--            <div class="d-flex mt-2">-->
<!--              <div class="day">Вт - Пт:</div>-->
<!--              <div class="time">-->
<!--                <p>8:00 - 8:30</p>-->
<!--                <p>11:00 - 11:30</p>-->
<!--                <p>14:40 - 15:10</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="d-flex mt-2">-->
<!--              <div class="day">Нд:</div>-->
<!--              <div class="time">-->
<!--                <span style="color:#000;">10:00 - 14:00 (забір вантажу без опрацювання)</span>-->
<!--              </div>-->
<!--            </div>-->

          </div>
        </template>

      </div>

    </div>
  </div>
</template>

<script>


  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
  export default {
    name: "NPPickUpSchedule",

    components: {
      RadioDefault
    },

    computed: {
    },

    data() {
      return {
        city: 'lviv'
      }
    },


  }
</script>

<style lang="scss">
  @import "../../../../../scss/colors";
  @import "../../../../../scss/mixins/mixins";

  .name-city {
    font-weight: bold;
    margin-bottom: 10px;
    color: #000;
  }

  .day {
    width: 25%;
  }

  .time {
    width: 75%;

    p {
      margin-bottom: 5px;
      margin-top: 0;
      color: #000;
    }
  }

  .payment-block {
    background: white;
    box-shadow: 0px 4px 16px #E7DFD5;
    border-radius: 5px;
    height: 100%;

    &__inner {
      padding: 24px;
      display: flex;
      flex-wrap: wrap;
      height: 100%;

    }

    &__col {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (min-width: 550px) and (max-width: 1400px) {

        &:nth-child(1) {
          width: 60%;
        }

        &:nth-child(2) {
          align-items: flex-end;
          width: 40%;
        }
      }
    }

    &__name {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: $black;
      margin-bottom: 10px;
    }

    &__description {
      font-size: 13px;
      line-height: 19px;
      color: $black;
      margin-bottom: 10px;
    }

    &__currently {
      font-size: 14px;
      line-height: 21px;
      color: $borderBrown;
      margin-bottom: 25px;

      @media (min-width: 550px) and (max-width: 1400px) {
        margin-bottom: 10px;
      }

      span {
        color: $black;
      }
    }

    &__radio {
      display: flex;
      justify-content: space-between;
      margin: 0 -6px;
      margin-bottom: 25px;

      @media (min-width: 550px) and (max-width: 1400px) {
        margin: 0;
        margin-bottom: 15px;
        flex-direction: column;
        justify-content: inherit;
      }
    }

    &__radio-i {
      padding: 0 6px;
      width: 100%;

      @media (min-width: 550px) and (max-width: 1400px) {
        padding: 0;
      }
    }

    &__input {
      max-width: 137px;
      margin-bottom: 8px;
    }

    &__total {
      width: 100%;
    }

    &__btn {
      margin-top: auto;
      width: 100%;
    }

  }

</style>
